import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import SingleColumnBlock from "../components/singleColumnBlock"

import ResourceImages from "@components/ResourceImages"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

const QuickLinks = () => {
  return (
    <PageLayout>
      <SEO
        title="Quick Links | ArtVue Glass"
        ogDescription=""
      />
      <PageWrapper color="grey">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h1>Quick Links</h1>
            </div>
          }
        />
      </PageWrapper>
      <div style={{ margin: "0 0 0 0"}}>
        <ResourceImages />
      </div>
    </PageLayout>
  )
}

export default QuickLinks