import React from "react"
import "./index.scss"

const images = [
  {
    link: `https://www.aia.org/`,
    url: `/images/resources/aia-logo.jpg`,
    alt: "American Institute of Architects",
  },
  {
    link: `https://www.aiafla.org/`,
    url: `/images/resources/AIA-Florida-Logo-New-2021.jpg`,
    alt: "American Institute of Architects - Florida",
  },
  {
    link: `https://www.asid.org/`,
    url: `/images/resources/asid-logo.png`,
    alt: "American Society of Interior Designers",
  },
  {
    link: `https://www.interiordesign.net/`,
    url: `/images/resources/interiordesign-logo.png`,
    alt: "Interior Design",
  },
  {
    link: `https://iida.org/`,
    url: `/images/resources/iida-logo.png`,
    alt: "International Interior Design Association (IIDA)",
  },
  {
    link: `https://www.designguide.com/`,
    url: `/images/resources/design-guide.com-logo.png`,
    alt: "Design Guide",
  },
  {
    link: `https://www.glass.org/`,
    url: `/images/resources/nationalglassassociation_logo.png`,
    alt: "National Glass Association",
  },
  {
    link: `https://www.usgbc.org/`,
    url: `/images/resources/usgbc-logo.png`,
    alt: "U.S. Green Building Council",
  },
  {
    link: `https://www.usglassmag.com/`,
    url: `/images/resources/usglassmag-logo.png`,
    alt: "US Glass Mag",
  },
  {
    link: `https://www.glassmagazine.com/`,
    url: `/images/resources/glassmagazine_logo.png`,
    alt: "Glass Magazine",
  },
  {
    link: `https://www.sgcc.org/`,
    url: `/images/resources/sgcc-logo.jpg`,
    alt: "Safety Glazing Certification Council",
  },
  {
    link: `https://www.kuraray.us.com/products/films/sentryglas-sentryglas-expressions/`,
    url: `/images/resources/sentryglas-logo.png`,
    alt: "SentryGlas",
  },
]

export default function ResourceImages() {
  return (
    <div className="resource-images">
      <div className="grid">
        {images.map((image, i) => (
          <div className="grid-item" key={i}>
            <a href={image.link} target="_blank"><img src={image.url} alt={image.alt} /></a>
          </div>
        ))}
      </div>
    </div>
  )
}
